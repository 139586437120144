<div
  *ngIf="isLoaded && submittal"
  class="maincontainer light-theme"
>
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="allDocuments"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              
<!-- 
              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() || !submittal.IsQAQCCompliance ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button> -->
              <aecom-button
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>
              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="12">
            <aecom-form-group
              columnSpan="12"
              columns="2"
              title="Processing"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="completed"
                [label]="'1. Is this QA/QC compliant?'"
                [required]="true"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.IsQAQCCompliance ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQAQCComplience(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !submittal.IsQAQCCompliance ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQAQCComplience(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
            </aecom-form-group>
            <rejectForm style="grid-column: span 12 / auto;"
              *ngIf="!submittal.IsQAQCCompliance"
              [(submittalRejectDetail)]="submittal.submittal_reject_detail" 
              [(coverSheet)]="coverSheet" 
              [(removedFileIds)]="removedFileIds" 
              [entity]="entity" 
              [tempId]="submittal.tempId" 
              [canIssueSubmittal]="canIssueSubmittal">
            </rejectForm>

            <ng-container *ngIf="submittal.IsQAQCCompliance">
              <aecom-form-group
                columnSpan="12"
                columns="2"
                title="Acknowledge receipt of documents?"
                style="margin-bottom: 15px; position: relative;"
                class="form-group-header-with-subtitle"
              >
                <div class="header-section" style="color: red; font-size: 16px; font-weight: bold;">By clicking yes, you are acknowledging the received documents are acceptable</div>
                <aecom-form-item
                  data-cy="acknowledge"
                  columnSpan="1"
                >
                  <div style="width: 50%;" class="button-wrapper d-flex">
                    <aecom-button innerClass="btn solid btn-tab blue">
                      Yes
                    </aecom-button>
                  </div>
                </aecom-form-item>

                <aecom-form-item
                  [label]="'Note for DB'"
                  [property]="
                    getNotesLength() + '/' + notesMaxLength + ' characters'
                  "
                  columnSpan="2"
                  class="formItemNoMargin"
                >
                  <aecom-textbox
                    data-cy="notes"
                    rows="3"
                    [theme]="'light'"
                    [notEmpty]="true"
                    [maxlength]="notesMaxLength"
                    [isMultiLine]="true"
                    [(ngValue)]="submittal.submittal_acknowledgement.Notes"
                    (ngValueChange)="setNotes($event)"
                    [validate]="getNotesLength() > notesMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_acknowledgement.Notes)"
                  ></aecom-textbox>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="12"
                columns="1"
                title="Assign OBSERVER(s)"
                style="position: relative"
                data-cy="assignWatcher"
              >
                <div style="width: 100%; height: 250px" class="noTableFilter">
                  <aecom-table
                    class="asignUserTable"
                    [ngValue]="watcherTableData"
                    (ngValueChange)="watcherTableRows = $event"
                    [theme]="'light'"
                    innerClass="list"
                  >
                    <tbody>
                      <tr
                        *ngFor="let bRow of watcherTableRows"
                        class="assignUser_tr_hover"
                      >
                        <ng-container *ngFor="let hCol of tableHeader">
                          <td *ngIf="hCol.Type !== columnType.Hide">
                            <span class="assignUser_td_Item">
                              {{ bRow[hCol.Name] }}
                            </span>
                          </td>
                        </ng-container>
                        <td>
                          <a
                            class="btn"
                            (click)="removeUserClick(bRow)"
                            ><i
                              class="
                                icon
                                icon-social_user_remove
                                icon-orange icon-lg
                              "
                            ></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </aecom-table>

                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                    "
                    *ngIf="watcherTableData.length === 0"
                  >
                    <div class="noItem-body">
                      <div>
                        <div
                          class="
                            noItem-icon-row
                            icon
                            icon-social_user_team_big
                            icon-grey icon-xl
                          "
                        ></div>
                      </div>
                      <div class="noItem-title-row">None</div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    top: 7px;
                    right: 0;
                    cursor: pointer;
                  "
                >
                  <div
                    data-cy="addWatcher"
                    class="formGroupManu"
                    (click)="openWindowClick()"
                  >
                    + Add OBSERVERS
                  </div>
                </div>
              </aecom-form-group>
            </ng-container>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="selectUserWindowTitle"
      (close)="closeWindow($event)"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>