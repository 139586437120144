import { DOCUMENT } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalSpecificationService from "@services/local-specification.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import LocalTaskService from "@services/local-task.service";
import LocalUserService from "@services/local-user.service";
import NavService from "@services/nav.service";
import RouteParams from "@shared/route-params";
import { submittalAppId } from "@shared/staticValue";
import { combineUserInfo } from "@shared/utils";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, concatMap, first, map } from "rxjs/operators";
import environment from "src/environments/environment";
import {
  ContractService,
  ContractUserService,
  INavigationRoot,
  IPRFISpecification,
  IPSubListItem,
  ITaskTab,
  IUser,
  NavigationService,
  RFISpecificationService,
  SubmittalDocumentType,
  SubmittalDocumentTypeService,
  SubmittalPage,
  SubmittalPageService,
  SubmittalService,
  SubmittalType,
  SubmittalTypeService,
  TaskService,
  TPContract,
  TPContractUser,
  UserService,
  PhaseService,
  RFIContractRequirementService,
  Phase,
  IPRFIContractRequirement,
  ApplicationRoleService,
  ApplicationRole,
  ContractUserApplicationService,
  SubmittalKeywords,
} from "../api-generated";
import LocalPhaseService from "@services/local-phase.service";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
import LocalApplicationRoleService from "@services/local-application_role.service";
import SubmittalRole from "@models/submittalRole";
import ContractNestedViewItem from "@models/contractNestedViewItem";
import BaseFunctionService from "@services/baseFunction.service";
import LocalKeyWordsService from "@services/local-keywords.service";
import { AuthService } from "@bbj/components";

@Injectable({
  providedIn: "root",
})
export default class LoginUserResolve {
  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    public router: Router,
    private contractService: ContractService,
    private contractUserService: ContractUserService,
    private userService: UserService,
    private rfiSpecificationService: RFISpecificationService,
    private submittalDocumentTypeService: SubmittalDocumentTypeService,
    private submittalTypeService: SubmittalTypeService,
    public submittalPageService: SubmittalPageService,
    private phaseService: PhaseService,
    private contractRequirementService: RFIContractRequirementService,
    private applicationRoleService: ApplicationRoleService,
    public submittalService: SubmittalService,
    public contractUserApplicationService: ContractUserApplicationService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public localContractService: LocalContractService,
    public localSpecificationService: LocalSpecificationService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
    public localSubmittalPageService: LocalSubmittalPageService,
    public localPhaseService: LocalPhaseService,
    public localContractRequirementService: LocalContractRequirementService,
    public localTaskService: LocalTaskService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public localSubmittalItemService: LocalSubmittalItemService, 
    public localKeyWordsService: LocalKeyWordsService,
    public navService: NavService,
    public navigationService: NavigationService,
    public taskService: TaskService,
    public baseFunctionService: BaseFunctionService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void | never> {
    this.loadingService.start();
    console.log("Hitting this");
    const contractId = route.params[RouteParams.ContractId];
    if (!contractId) {
      this.document.location.href = environment.url.portal;
    }
    this.baseFunctionService.setContractId(contractId);
    return from(this.authService.login()).pipe(
      concatMap((userId) => {
        if (!userId) {
          return of([]);
        }

        const localContract = this.localContractService.getItem();
        const localContractUsers = this.localContractUserService.getItems();
        const localUsers = this.localUserService.getItems();
        const localSpecifications = this.localSpecificationService.getItems();
        const localSubmittalType = this.localSubmittalTypeService.getItems();
        const localDocumentType = this.localSubmittalDocumentTypeService.getItems();
        const localPages = this.localSubmittalPageService.getItems();
        const localPhase = this.localPhaseService.getItems();
        const localContractRequirement = this.localContractRequirementService.getItems();
        const localKeywords = this.localKeyWordsService.getItems();
        const localRole = this.localContractUserService.currentUserContractRole;
        const boroughNavigation = this.navService.getBroughData();
        const combinedBoroughs = this.navService.getrecentvalue();
        const globalNavigation = this.navService.getGlobalData();
        const localTasks = this.localTaskService.getItems();
        const localSubmittalItems = this.localSubmittalItemService.getItems();
        const localApplicationRoles = this.localApplicationRoleService.getAppRoles();
        const localNestedViewFlag = this.localSubmittalItemService.nestedView;
        const nestedViewItem = new ContractNestedViewItem(userId, contractId);
        if (
          !localContract ||
          !localContractUsers ||
          !localUsers ||
          !localApplicationRoles ||
          !localSpecifications ||
          !localSubmittalType ||
          !localDocumentType ||
          !localPages ||
          !localPhase ||
          !localContractRequirement ||
          !localKeywords ||
          !localRole ||
          localContract.Guid !== contractId ||
          !localSubmittalItems ||
          !localTasks
        ) {
          this.localSubmittalItemService.firstLoad = true;
          return forkJoin([
            // this.userService.getAllSubmittalUsersByContractId(contractId),
            this.userService.getAll(),
            this.submittalService.getAllSubItems(contractId),
            this.taskService.getTasks(),
            this.contractService.getContractById(contractId),
            this.contractUserService.getContractUser(contractId),
            this.applicationRoleService.getAllApplicationRole(),
            this.submittalService.getSubmittalRole(contractId),
            this.contractUserApplicationService.getApplicationNestedVeiw(nestedViewItem),
            this.rfiSpecificationService.getAllRFISpecification(),
            this.submittalDocumentTypeService.getAllSubmittalDocumentType(),
            this.submittalPageService.getAllSubmittalPage(),
            this.phaseService.getAllPhase(),
            this.contractRequirementService.getAllRFIContractRequirement(),
            this.submittalTypeService.getAllSubmittalType(),
            this.submittalService.getSubmittalKeyWords(),
            this.navigationService.getAllMenus(),
          ]);
        }

        return of([
          localUsers,
          localSubmittalItems,
          localTasks,
          localContract,
          localContractUsers,
          localApplicationRoles,
          localRole,
          localNestedViewFlag,
          localSpecifications,
          localDocumentType,
          localPages,
          localPhase,
          localContractRequirement,
          localSubmittalType,
          localKeywords,
          { boroughNavigation, combinedBoroughs, globalNavigation },
        ]);
      }),
      map(
        ([
          users,
          subItems,
          tasks,
          contract,
          contractUsers,
          applicationRoles,
          role,
          nestedViewFlag,
          specifications,
          documentType,
          pages,
          phases,
          contractRequirements,
          submittalType,
          keywords,
          root,
        ]: [
          IUser[],
          IPSubListItem[],
          ITaskTab[],
          TPContract,
          TPContractUser[],
          ApplicationRole[],
          string,
          boolean,
          IPRFISpecification[],
          SubmittalDocumentType[],
          SubmittalPage[],
          Phase[],
          IPRFIContractRequirement[],
          SubmittalType[],
          SubmittalKeywords[],
          INavigationRoot,
        ]) => {
          const loginUser = users.find((u)=>{return u.id === this.authService.getUserId()});
          if (!users || !contract || !loginUser) {
            this.document.location.href = environment.url.portal;
          }
          const permission = this.authService.getPermission();
          this.navService.setUserItem(loginUser.givenName, loginUser.surname, loginUser.id, loginUser.mail, loginUser.companyName, permission);
          this.localContractService.currentContract = contract;
          this.localContractUserService.currentUserContractRole = role;

          const doc = contractUsers.find((cu) => {
            return cu.contract_user_application_role.find((a) => {
              return (
                a.ApplicationId === submittalAppId &&
                a.ApplicationRoleId === SubmittalRole.Doc_Control
              );
            });
          });

          this.localContractUserService.defaultDoc = doc?.UserId ?? null;
          this.localContractService.setItem(contract);
          this.localApplicationRoleService.setItems(applicationRoles);

          const contractUsersWithInfo: IContractUserWithUserInfo[] =
            contractUsers
              .map((cu) => {
                const userInfo = users.find((u) => {
                  return u.id === cu.UserId;
                });
                return combineUserInfo(cu, userInfo);
              })
              .filter((cu) => {
                return cu.UserInfo;
              });
          const filteredDocumentTypes = documentType.filter((item) => {
            if(!item.ContractCode)
            {
              return item;
            } else {
              const codeArr = item.ContractCode.split(',');
              return codeArr.some((code)=>{return contract.Code.includes(code)});
            }
          });
          const filteredSpecifications = specifications.filter((item) => {
            const codeArr = item.ContractCode.split(',');
            return codeArr.some((code)=>{return contract.Code.includes(code)});
          });
          this.localContractUserService.setItems(contractUsersWithInfo);
          this.localUserService.setItems(users);
          this.localSpecificationService.setItems(filteredSpecifications);
          this.localSubmittalTypeService.setItems(submittalType);
          this.localSubmittalDocumentTypeService.setItems(filteredDocumentTypes);
          this.localSubmittalPageService.setItems(pages);
          this.localPhaseService.setItems(phases);
          this.localContractRequirementService.setItems(contractRequirements);
          this.localKeyWordsService.setItems(keywords);
          this.localSubmittalItemService.setItems(subItems);
          this.localTaskService.setItems(tasks);
          this.localSubmittalItemService.nestedView = nestedViewFlag;
          if (root) {
            this.navService.setBroughData(root.boroughNavigation);
            this.navService.setGlobalData(root.globalNavigation);
            this.navService.setDataSubject(root.combinedBoroughs);

            if (root.globalNavigation.length) {
              this.navService.setSelectedId(
                root.globalNavigation[0].globalModule.Guid,
              );
            } else if (root.boroughNavigation.length) {
              this.navService.setSelectedId(
                root.boroughNavigation[0].boroughItem.Guid,
              );

              this.localContractService.setItem(
                root.boroughNavigation[0].projects[0],
              );
            }
          }

          return;
        },
      ),
      first(),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 404) {
          this.document.location.href = environment.url.portal;
        } else {
          const localContract = this.localContractService.getItem();
          if (localContract?.Guid) {
            this.router.navigate([
              localContract.Guid,
              "error",
              error.status ? error.status : 500,
            ]);
          } else {
            this.router.navigate(["error", error.status ? error.status : 500]);
          }
        }
        return of();
      }),
    );
  }
}
