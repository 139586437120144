import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubAcknowledgedStep } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetails from "./submittalRejectDetail";
import SubmittalAcknowledgementBase from './submittalAcknowledgementBase';

export default class SubmittalAcknowledgement
  extends SubmittalItemUpdateBase
  implements ISubAcknowledgedStep
{
  public submittal_acknowledgement: SubmittalAcknowledgementBase;

  public IsQAQCCompliance: boolean;

  public tempId?: string;
  
  public submittal_reject_detail?: SubmittalRejectDetails;

  public submittal_watcher: string[];

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);
    this.IsQAQCCompliance = true;
    
    this.tempId = uuidv4();
    this.submittal_watcher =
      item.submittal_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];
    this.submittal_reject_detail = new SubmittalRejectDetails();

    this.submittal_acknowledgement = new SubmittalAcknowledgementBase();
  }
}
