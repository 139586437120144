import { IPSubItem, ISubDelegateDetail } from "../api-generated";
import TimeStamp from "./timeStamp";
import SubmittalStatus from "./submittalStatus";
import SubmittalRole from "./submittalRole";

export default class SubmittalDelegateDetail
  extends TimeStamp
  implements ISubDelegateDetail
{
  public UserId: string;
  public RoleId: string;

  constructor(item: IPSubItem) {
    super();
    this.RoleId = this.getRoleIdByStatus(item);
  }

  private getRoleIdByStatus(item: IPSubItem): string {
    let result = "";
    switch(item.Status)
    {
      case SubmittalStatus.In_Review:
      case SubmittalStatus.Compiling:
      case SubmittalStatus.Bluebeam_Compiling:
      case SubmittalStatus.Bluebeam:
      case SubmittalStatus.Bluebeam_Request:
      case SubmittalStatus.Bluebeam_Uploaded:
        result = SubmittalRole.Coordinator;
        break;
      case SubmittalStatus.Disposition:
      case SubmittalStatus.Compiling_Disposition:
      case SubmittalStatus.Bluebeam_Disposition:
        result = SubmittalRole.Manager;
        break;
      case SubmittalStatus.DDC_Disposition:
        result = SubmittalRole.DDC_Manager;
        break;
      case SubmittalStatus.SPM_Review:
        result = SubmittalRole.SR_Manager;
        break;
      case SubmittalStatus.Acknowledgement:
        result = SubmittalRole.Selected;
        break;
      default:
        break;
    }
    return result
  }
}
