import {
  IPSubDocumentDownload,
  IPSubItem,
  SubmittalCompile,
  SubmittalDisposition,
  SubmittalNotes
} from "../api-generated";
import SubmittalRole from "./submittalRole";
import { getDispositionText, getFilesByType, getRoleForSubmittal, prepareMutiLineText } from "@shared/utils";
import FileModelWithVersion from "./fileModelWithVersion";
import SubmittalFileType from "./submittalFileType";
import LocalApplicationRoleService from "@services/local-application_role.service";
import SubmittalStatus from "./submittalStatus";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";

export default class NotesListItem {
  public Name: string;

  public Role: string;

  public Title: string;

  public FormatedDateUpdated: string;

  public Notes: string;

  public Collapse: boolean;

  public Sort: number;

  public IsBack: boolean;

  public DispositionText?: string;

  public CoverSheet?: FileModelWithVersion[];

  public CommentResolutionForm?: FileModelWithVersion[];

  public MarkedUpComments?: FileModelWithVersion[];

  constructor(item: SubmittalNotes | SubmittalCompile | SubmittalDisposition, users: IContractUserWithUserInfo[], localApplicationRoleServices: LocalApplicationRoleService, submittal: IPSubItem, documents: IPSubDocumentDownload[]) {
    if(this.instanceOf_SubmittalNotes(item))
    {
      const role = getRoleForSubmittal(item.UpdatedBy,submittal,users,item.DateUpdated);
      const user = users.find((u)=>{return u.UserId === item.UpdatedBy});

      this.Name = user?.UserInfo?.displayName ?? "";
      this.Role = `${localApplicationRoleServices.getRoleById(role)}`;
      switch(item.Status)
      {
        case SubmittalStatus.Compiling_Disposition:
        case SubmittalStatus.Disposition:
        case SubmittalStatus.SPM_Review:
        case SubmittalStatus.DDC_Disposition:
          this.IsBack = true;
          this.Title = "Send Back Note to Coordinator"
          break;
        default:
          this.IsBack = false;
          break;
      }
      this.Notes = prepareMutiLineText(item.Notes);
      this.FormatedDateUpdated = item.NotedDate
        ? `${new Date(item.NotedDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })} ${new Date(item.NotedDate).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          })}`
        : "-";
      this.Sort = item.NotedDate ? new Date(item.NotedDate).getTime() : -1;
    }
    else
    {
      this.IsBack = false;
      if (item.UpdatedBy) {
        const role = getRoleForSubmittal(item.UpdatedBy,submittal,users,item.DateUpdated);
        const user = users.find((u)=>{return u.UserId === item.UpdatedBy});

        this.Name = user?.UserInfo?.displayName ?? "";
        this.Role = `${localApplicationRoleServices.getRoleById(role)}`;
      }
      // this.Title = this.instanceOf_SubmittalDisposition(item) ? "Note for DB" : "Note to Manager";
      this.Title = "Note for DB";
      this.FormatedDateUpdated = item.DateUpdated
        ? `${new Date(item.DateUpdated).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })} ${new Date(item.DateUpdated).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          })}`
        : "-";
      this.Notes = prepareMutiLineText(item.Note);
      this.Sort = item.DateUpdated ? new Date(item.DateUpdated).getTime() : -1;
      this.DispositionText = getDispositionText(item.Disposition, submittal.submittal_create[0].SubmittalTypeId);
  
      const documnetsForThis = documents.filter((doc) => {
        return doc.ReferenceId === item.Guid;
      });
  
      this.CoverSheet = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CoverSheetStampSigned,
      );
  
      this.CommentResolutionForm = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CommentResolutionForm,
      );
  
      this.MarkedUpComments = getFilesByType(
        documnetsForThis,
        SubmittalFileType.MarkedUpComments,
      );
    }
    this.Collapse = true;
  }

  instanceOf_SubmittalNotes(object: SubmittalNotes | SubmittalCompile | SubmittalDisposition): object is SubmittalNotes {
    return 'NotedBy' in object;
  }

  instanceOf_SubmittalDisposition(object: SubmittalNotes | SubmittalCompile | SubmittalDisposition): object is SubmittalDisposition {
    return 'ToDDC' in object;
  }
}
