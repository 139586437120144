import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import tableHeader from "@content/selectUser/table-header";
import TableHeaderCol from "@content/selectUser/table-header-col";
import RowItem from "@content/selectUser/table-row";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ColumnType from "@models/columnType";
import SubmittalAcknowledgeQaqc from "@models/submittalAcknowledgeQaqc";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import BaseFunctionService from "@services/baseFunction.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import { assignWatchersTitle } from "@shared/staticValue";
import {
  hasChangesFileModel,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPSubItem,
} from "src/app/api-generated";
import { IPSubDocumentDownload } from "src/app/api-generated/model/iPSubDocumentDownload";

@Component({
  selector: "app-acknowledgementQaqc",
  templateUrl: "./acknowledgementQaqc.component.html",
  styleUrls: ["./acknowledgementQaqc.component.scss"],
})
export default class AcknowledgementQaqcComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  contractId: string;
  currentUser: string;

  submittal: SubmittalAcknowledgeQaqc;
  oldSubmittal: SubmittalAcknowledgeQaqc;

  entity: IPSubItem;

  allUserTableRows: RowItem[] = [];
  watcherWindowTitle = assignWatchersTitle;
  watcherTableData: RowItem[] = [];
  watcherTableRows: RowItem[] = [];
  
  selectUserWindowTitle: string;
  showSelectUser = false;
  tableRows: RowItem[] = [];
  tableHeader: TableHeaderCol[] = tableHeader;
  activeSave = false;
  columnType = ColumnType;

  BICList: ISelectItem[] = [];

  canIssueSubmittal = false;

  allDocuments: IPSubDocumentDownload[];
  coverSheet: FileModel[] = [];
  oldCoverSheet: FileModel[] = [];
  coverSheetNameValid = true;

  isLoaded = false;
  removedFileIds: string[] = [];

  notesMaxLength = 250;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public localContractuServices: LocalContractService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.contractId = this.localContractuServices.currentContract.Guid;
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;
    this.currentUser = data.currentUserId;
    this.entity = data.submittal;
    this.BICList = data.bicList;
    this.oldSubmittal = new SubmittalAcknowledgeQaqc(this.contractId, data.submittal);
    
    this.allDocuments = data.documents;
    this.allUserTableRows = data.allUsers;
    this.watcherTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubmittal.submittal_watcher.includes(u.id);
    });
    this.submittal = _.cloneDeep(this.oldSubmittal);
    this.isLoaded = true;
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const submittal = JSON.stringify(this.submittal);
    const oldSubmittal = JSON.stringify(this.oldSubmittal);

    return (
      oldSubmittal != submittal || hasChangesFileModel(this.coverSheet, this.oldCoverSheet)
    );
  }

  IsInputValid(): boolean {
    if (this.submittal.IsQAQCCompliance) {
      return this.submittal.submittal_notes.Notes.trim().length <= this.notesMaxLength 
        && !this.baseFunctionService.isInputInvalid(this.submittal.submittal_notes.Notes);
    } else {
      return !this.baseFunctionService.isInputInvalid(this.submittal.submittal_reject_detail.Note);
    }
  }

  IsValid(): boolean {
    if (this.submittal.IsQAQCCompliance) {
      return !_.isEmpty(this.submittal.submittal_select_bic.BIC);
    } else {
      return (this.submittal.submittal_reject_detail?.Reason) && this.coverSheet.length > 0;
    }
  }

  submit(): void {
    this.canIssueSubmittal = true;

    const validation = this.IsInputValid() && this.IsValid();

    if (!validation) {
      return;
    } else if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      let title: string;

      if (!this.submittal.IsQAQCCompliance) {
        title = "Reject Submittal?";
      } else {
        title = "Confirm Action?";
      }

      const body = this.submittal.IsQAQCCompliance
        ? "Once submitted, your task will be completed and you will no longer be able to make updates. The submittal will be moved to the next step in the workflow."
        : "The Submittal will be sent back to the DB User. Once it's submitted, you will no longer be able to edit the submittal.";

      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";

      modalInstance.instance.title = title;

      modalInstance.instance.body = body;

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_notes.IsDraft = false;
          this.update();
        }
      });
    }
  }

  update() {
    this.loadingService.start();
    this.updateFiles();
    if(!this.submittal.IsQAQCCompliance) {
      this.submittal.submittal_notes.Notes = '';
    }
    this.baseFunctionService.update(this.submittal);
  }

  updateFiles(): void {
    const allCurrentFiles = [...this.coverSheet];
    const allOldFiles = [...this.oldCoverSheet.map((f) => f.Guid)];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  setBIC(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_select_bic.BIC = e.id;
    }
  }

  setQAQCComplience(e: boolean): void {
    this.submittal.IsQAQCCompliance = e;
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  removeUserClick(row: RowItem): void {
    this.watcherTableData = this.watcherTableData.filter(
      (watcher) => watcher.id !== row.id,
    );
    this.submittal.submittal_watcher = this.watcherTableData.map((u) => {
      return u.id;
    });
  }

  openWindowClick(): void {
    this.activeSave = false;

    this.selectUserWindowTitle = this.watcherWindowTitle;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        return (
          this.watcherTableRows.findIndex((u) => {
            return u.id == user.id;
          }) == -1
        );
      })
      .map((user) => {
        user.checked = false;

        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        this.watcherTableData = this.watcherTableData.concat(e);
        this.submittal.submittal_watcher = this.watcherTableData.map(
          (u) => {
            return u.id;
          },
        );
        this.showSelectUser = false;
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  hasFilesUploading(): boolean {
    return this.coverSheet.some(
      (item) => item.Status === UploadStatus.UPLOADING,
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.back();
  }
  getNotesLength(): number {
    return this.submittal.submittal_notes?.Notes ? this.submittal.submittal_notes.Notes.length : 0;
  }
  setNotes(e: string): void {
    this.submittal.submittal_notes.Notes = e;
  }
}
