import { ISubAcknowledgement } from "../api-generated";

export default class SubmittalAcknowledgementBase
  implements ISubAcknowledgement
{
  public Guid?: string;

  public Notes?: string;

  constructor() {
    this.Notes = '';
  }
}
