import { ISubSelectedBIC } from "../api-generated";

export default class SubmittalSelectBICBase
  implements ISubSelectedBIC
{
  public Guid?: string;

  public BIC: string;

  constructor() {
    this.BIC = '';
  }
}
