enum SubmittalStatus {
  Draft = "Draft",
  Submitted = "Submitted",
  Rejected = "Rejected",
  In_Review = "In Review",
  Bluebeam = "Bluebeam",
  Bluebeam_Compiling = "Bluebeam Compiling",
  Bluebeam_Request = "Bluebeam Request",
  Bluebeam_Uploaded = "Bluebeam Uploaded",
  Compiling = "Compiling",
  Disposition = "Disposition",
  DDC_Disposition = "DDC Disposition",
  In_Revision = "In Revision",
  Revise_Resubmit = 'Revise & Resubmit',
  Resubmitted = 'Resubmitted',
  Reviewed = 'Reviewed',
  Reviewed_With_Comments = 'Reviewed With Comments',
  Accepted = 'Accepted',
  Accepted_With_Comments = 'Accepted With Comments',
  Recalled = "Recalled",
  Canceled = "Canceled",
  Voided = "Voided",
  Bluebeam_Disposition = "Bluebeam Disposition",
  Compiling_Disposition = "Compiling Disposition",
  QAQC_Fail = 'QAQC Fail',
  Distribution = 'Distribution',
  Review_Period = 'Review Period',
  SPM_Review = 'SPM Review',
  PM_Review = 'PM Review',
  Denied = 'Denied',
  Request_to_Reopen = "Request to Reopen",
  Request_Completed = "Request Completed",
  Reopen_in_Progress = "Reopen in Progress",
  DB_Acknowledgment = "DB Acknowledgment",
  Acknowledgement = "Acknowledgement",
  Acknowledged = "Acknowledged"
}

export default SubmittalStatus;
