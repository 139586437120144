import { Component, Input, OnInit } from "@angular/core";
import { IPSubItem } from "src/app/api-generated";
import SubmittalStatus from "@models/submittalStatus";
import { getStatusText } from "@shared/utils";

@Component({
  selector: "subHeader",
  templateUrl: "./subHeader.component.html",
  styleUrls: ["./subHeader.component.scss"],
})
export default class SubHeaderComponent implements OnInit {
  @Input() submittal: IPSubItem;

  @Input() isDBAdmin = false;

  number: string;

  title: string;

  icon: string;

  status: string;

  priority: string;

  priorityBackground: string;

  isconfidential: boolean;

  ngOnInit(): void {
    this.number = this.submittal.SubmittalNumber;
    this.title = this.submittal.submittal_create[0]?.Title ?? "";
    const isReopen = this.submittal.submittal_reopen?.length > 0;
    this.status = getStatusText(this.submittal.IsRecalled ? SubmittalStatus.Recalled : this.submittal.Status, this.isDBAdmin, isReopen);
    this.isconfidential =
      this.submittal.submittal_create[0]?.IsConfidentail ?? null;

    this.icon = this.getIcon(this.submittal, this.isDBAdmin);

    switch (this.submittal.submittal_create[0]?.Priority) {
      case 2:
        this.priority = "Medium";
        this.priorityBackground = "btn sm solid orange unchange";
        break;
      case 3:
        this.priority = "High";
        this.priorityBackground = "btn sm solid red unchange";
        break;
      default:
        this.priority = "Low";
        this.priorityBackground = "btn sm solid grey unchange";
    }
  }

  getIcon(submittal: IPSubItem, isDB: boolean): string {
    const status = submittal.IsRecalled ? SubmittalStatus.Recalled : submittal.Status;
    let iconClass = "";

    switch (status) {
      case SubmittalStatus.Submitted:
        iconClass = "icon-action_send title-icon-purple";
        break;
      case SubmittalStatus.In_Review:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-action_rotate_anticlockwise title-icon-purple";
        break;
      case SubmittalStatus.Compiling:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-action_combine title-icon-purple";
        break;
      case SubmittalStatus.Compiling_Disposition:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-action_combine title-icon-purple";
        break;
      case SubmittalStatus.Bluebeam_Disposition:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-bluebeam title-icon-bluebeam";
        break;
      case SubmittalStatus.Bluebeam:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-bluebeam title-icon-bluebeam";
        break;
      case SubmittalStatus.Bluebeam_Compiling:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-bluebeam title-icon-bluebeam";
        break;
      case SubmittalStatus.Bluebeam_Uploaded:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-bluebeam title-icon-bluebeam";
        break;
      case SubmittalStatus.Bluebeam_Request:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-bluebeam title-icon-bluebeam";
        break;
      case SubmittalStatus.Disposition:
      case SubmittalStatus.SPM_Review:
      case SubmittalStatus.DDC_Disposition:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-document_accept title-icon-purple";
        break;
      case SubmittalStatus.Acknowledgement:
        iconClass = isDB
          ? "icon-action_send title-icon-purple"
          : "icon-action_exclamation title-icon-purple";
        break;
      case SubmittalStatus.Acknowledged:
        iconClass = "icon-action_exclamation title-icon-purple";
        break;
      case SubmittalStatus.Revise_Resubmit:
        iconClass = "icon-action_clockwise title-icon-purple";
        break;
      case SubmittalStatus.Reviewed:
        iconClass = "icon-action_special title-icon-purple";
        break;
      case SubmittalStatus.Accepted:
        iconClass = "icon-action_special title-icon-purple";
        break;
      case SubmittalStatus.Reviewed_With_Comments:
        iconClass = "icon-action_special title-icon-purple";
        break;
      case SubmittalStatus.Accepted_With_Comments:
        iconClass = "icon-action_special title-icon-purple";
        break;
      case SubmittalStatus.QAQC_Fail:
      case SubmittalStatus.Denied:
        iconClass = "icon-document_close title-icon-purple";
        break;
      case SubmittalStatus.Resubmitted:
      case SubmittalStatus.In_Revision:
        iconClass = "icon-action_superseded title-icon-purple";
        break;
      case SubmittalStatus.Canceled:
      case SubmittalStatus.Voided:
      case SubmittalStatus.Recalled:
        iconClass = "icon-action_remove_circle title-icon-purple";
        break;
      case SubmittalStatus.Request_to_Reopen:
        iconClass = "icon-action_re-send title-icon-purple";
        break;
      default:
        break;
    }

    return iconClass;
  }
}
