import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "@bbj/components";
import IWorkflowHistoryItem from "@models/IWorkflowHistoryItem";
import NotesListItem from "@models/notesListItem";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import BaseFunctionService from "@services/baseFunction.service";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import { FAC_Contracts, TSB_Contracts } from "@shared/staticValue";
import { getAccTimeDate, getBusinessDatesCount, getFixTimeDate, getStatusText, getUserNameById, isFinalStep, isFinalStepForBluebeam, shouldShowCoordinator, shouldShowDDCManager, shouldShowDocControl, shouldShowManager, shouldShowSrManager } from "@shared/utils";
import {
  IPSubDocumentDownload,
  IPSubItem,
  IUser,
} from "src/app/api-generated";

@Component({
  selector: "subProcessingTab",
  templateUrl: "./subProcessingTab.component.html",
  styleUrls: ["./subProcessingTab.component.scss"],
})
export default class SubProcessingTabComponent implements OnInit {
  @Input() entity: IPSubItem;
  @Input() docs: IPSubDocumentDownload[];
  @Input() allowMovingFiles = true;

  @Output() moveCoverSheet = new EventEmitter<FileModel>();

  @Output() moveCoverSheets = new EventEmitter<FileModel[]>();

  @Output() moveResolutionForm = new EventEmitter<FileModel>();

  @Output() moveResolutionForms = new EventEmitter<FileModel[]>();

  @Output() moveComment = new EventEmitter<FileModel>();

  @Output() moveComments = new EventEmitter<FileModel[]>();

  items: NotesListItem[] = [];

  isLoaded = false;

  manager: IUser;
  srManager: IUser;
  docControlUser: IUser;
  coordinator: IUser;
  ddcManager: IUser;

  showManager: boolean;
  showSrManager: boolean;
  showDocControl: boolean;
  showCoordinator: boolean;
  showDDCManager: boolean;

  keywords: string[] = [];

  workflowHistorys: IWorkflowHistoryItem[] = [];

  showWorkflow: boolean;

  showSRManager: boolean;

  canUpdateDueDate: boolean = false;
  
  constructor(
    public authService: AuthService,
    public localUserService: LocalUserService,
    public localContractUserService: LocalContractUserService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public localContractService: LocalContractService,
    public baseFunctionService: BaseFunctionService
  ) {}

  ngOnInit(): void {
    const users = this.localUserService.getItems();
    const currentUser = this.authService.getUserId();
    const userRole =
      this.localContractUserService.getUserById(currentUser).RolesId || "User";
    this.showWorkflow = userRole !== SubmittalRole.DBAdmin;
    this.docControlUser = users.find((u)=>{return u.id === this.entity.DocControlId});
    this.manager = users.find((u)=>{return u.id === this.entity.ManagerId});
    this.srManager = users.find((u)=>{return u.id === this.entity.SrManagerId});
    this.coordinator = users.find((u)=>{return u.id === this.entity.CoordinatorId});
    this.ddcManager = users.find((u)=>{return u.id === this.entity.DDCManagerId});
    this.keywords = SubProcessingTabComponent.sortKeyWords(this.entity.KeyWords);
    this.showManager = shouldShowManager(this.entity);
    this.showSrManager = shouldShowSrManager(this.entity);
    this.showCoordinator = shouldShowCoordinator(this.entity);
    this.showDDCManager = shouldShowDDCManager(this.entity);
    this.showDocControl = shouldShowDocControl(this.entity);
    const contractCode = this.localContractService.currentContract.Code;
    this.showSRManager = contractCode.includes(FAC_Contracts) || contractCode.includes(TSB_Contracts);
    const compileStatusArr: string[] = [SubmittalStatus.Review_Period, SubmittalStatus.Compiling, SubmittalStatus.Bluebeam_Compiling];
    this.canUpdateDueDate = this.allowMovingFiles && compileStatusArr.includes(this.entity.Status) && (currentUser === this.entity.CoordinatorId || userRole === SubmittalRole.Doc_Control);
    const notes = this.entity.submittal_notes?.filter((n)=>{
      return n.IsDraft === false && n.Notes && n.DeletedBy === null && n.Notes.trim() !== ""
    });
    let NotesItems: NotesListItem[] = [];
    const contractUsers = this.localContractUserService.getItems();
    if(notes)
    {
      NotesItems = NotesItems.concat(notes.map((note)=>{
        return new NotesListItem(note, contractUsers, this.localApplicationRoleService, this.entity, this.docs);
      }));
    }
    if (
      this.entity.submittal_compile &&
      this.entity.submittal_compile.length > 0
    ) {
      const compile = [
        ...this.entity.submittal_compile
          .filter((item) => {
            return !item.IsDraft;
          })
      ];
      NotesItems = NotesItems.concat(compile.map((note)=>{
        return new NotesListItem(note, contractUsers, this.localApplicationRoleService, this.entity, this.docs);
      }));
    }
    if (
      this.entity.submittal_disposition &&
      this.entity.submittal_disposition.length > 0
    ) {
      const compile = [
        ...this.entity.submittal_disposition
          .filter((item) => {
            return !item.IsDraft;
          })
      ];
      NotesItems = NotesItems.concat(compile.map((note)=>{
        return new NotesListItem(note, contractUsers, this.localApplicationRoleService, this.entity, this.docs);
      }));
    }
    this.items = NotesItems.sort((a,b)=>b.Sort-a.Sort);
    if(this.items.length > 0)
    {
      this.items[0].Collapse = false;
    }
    if(this.items.length > 1 && this.items[0].Sort === this.items[1].Sort)
    {
      this.items[1].Collapse = false;
    }
    if(this.entity.submittal_flow?.length > 0)
    {
      let flow = this.entity.submittal_flow.filter((f)=>{return f.Status !== SubmittalStatus.Draft}).sort((a, b)=>{
        const timestampA = new Date(a.DateReceived).getTime();
        const timestampB = new Date(b.DateReceived).getTime();
        if(timestampA === timestampB)
        {
          console.log(a.Status,b.Status);
          return b.Status === SubmittalStatus.Request_to_Reopen ? 1 : -1;
        } else {
          return timestampA - timestampB;
        }
      });
      if(this.entity.IsBluebeamRequired)
      {
        flow = flow.filter((f)=>{return !(isFinalStepForBluebeam(f.Status)&&f.BIC===SubmittalRole.DBAdmin)})
      }
      if(flow.length > 0)
      {
        console.log(flow);
        this.workflowHistorys = this.workflowHistorys.concat(flow.map((f, i, a)=>{
          const sequence = i + 1;
          const step = getStatusText(f.Status);
          const timeSubmitted = getAccTimeDate(f.DateReceived);
          const submittedBy = getUserNameById(contractUsers, f.SubmittedBy);
          let bicUser = getUserNameById(contractUsers,f.BICUserId);
          let roleName = this.localApplicationRoleService.getRoleById(f.BIC);
          let daysInBic = "-";
          let actionDate: string | null = null;
          if(isFinalStep(f.Status))
          {
            daysInBic = "N/A";
            if(this.entity.IsBluebeamRequired)
            {
              bicUser = getUserNameById(contractUsers, this.entity.SubmittedBy);
              roleName = this.localApplicationRoleService.getRoleById(SubmittalRole.DBAdmin);
            }
          }
          else {
            if(i !== a.length - 1)
            {
              const actionStep = a[i+1];
              actionDate = actionStep.DateReceived;
            }
            daysInBic = getBusinessDatesCount(f.DateReceived, actionDate??new Date().toLocaleDateString()).toString();
          }
          
          const bic = bicUser? `${bicUser}${roleName===''?'':'('+roleName+')'}` : roleName;

          return {
            sequence,
            step,
            timeSubmitted,
            submittedBy,
            bic,
            daysInBic,
          }
        }));
      }
    }
    this.isLoaded = true;
  }

  getFixedDateTime(a: string): string {
    return getFixTimeDate(a);
  }

  coverSheetOut(item: FileModel): void {
    // console.log(item);
    this.moveCoverSheet.emit(item);
  }

  coverSheetsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveCoverSheets.emit(item);
  }

  resolutionFormOut(item: FileModel): void {
    // console.log(item);
    this.moveResolutionForm.emit(item);
  }

  resolutionFormsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveResolutionForms.emit(item);
  }

  commentOut(item: FileModel): void {
    // console.log(item);
    this.moveComment.emit(item);
  }

  commentsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveComments.emit(item);
  }

  static sortKeyWords(values?: string): string[] {
    if (values) {
      return values.trim().split(",").sort();
    }
    return [];
  }

  updateDueDate(): void {
    this.baseFunctionService.updateDueDate();
  }
}

