import {
  FileModel,
  groupData,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import SubmittalCreate from "@models/submittalCreate";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import { FAC_Contracts, MasterSpecType, ReviewTypeReadOnly, SPR_PerformanceSpecifications, TSB_Contracts, UniformatSpecType, versionConst } from "@shared/staticValue";
import {
  calculateReviewDate,
  canRevise,
  getFilesByType,
  getVersionString,
  hasChangesFileModel,
  isFileNameInvalid,
  isReviseDraft,
} from "@shared/utils";
import _, { isString } from "lodash";
import { Observable } from "rxjs";
import {
  IPRFIContractRequirement,
  IPRFISpecification,
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  Phase,
  SubmittalDocumentsService,
  SubmittalDocumentType,
  SubmittalPage,
  SubmittalType,
} from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export default class CreateComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  submittal: SubmittalCreate;

  oldSubmittal: SubmittalCreate;

  attemptToIssue = false;

  divisionList: ISelectItem[];

  specificationList: IPRFISpecification[];

  specificationCleanList: ISelectItem[];

  masterSpecList: ISelectItem[];

  allDocumentType: SubmittalDocumentType[];

  documentTypeList: ISelectItem[];

  submittalTypeList: ISelectItem[];

  drawingPageList: ISelectItem[];

  phaseList: ISelectItem[];

  contractRequirementList: ISelectItem[];

  contractId: string;

  contractCode: string;

  titleMaxLength = 100;

  referenceNumberMaxLength = 100;

  referenceNoteMaxLength = 250;

  priorityNoteMaxLength = 150;

  IsRevise = false;

  IsRevision = false;

  note = "";

  rejectedBy = "";

  reviseCoverSheet: FileModel[] = [];

  currentUser: string;

  coverSheet: FileModel[] = [];

  submittalDocuments: FileModel[] = [];

  oldCoverSheet: FileModel[] = [];

  oldSubmittalDocuments: FileModel[] = [];

  coverSheetNameValid = true;

  submittalDocumentsNameValid = true;

  enableSaveDraft = false;

  title = "";

  division: string;

  private removedFileIds: string[] = [];

  private pageList: SubmittalPage[];

  showPhaseAndContractRequirement = false;

  requireUniFormatCategory = false;

  showVoid = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public localContractService: LocalContractService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const {
      submittal,
      documents,
      submittalType,
      submittalPage,
      submittalDocumentType,
      submittalSpecification,
      contractId,
      currentUserId,
      phase,
      contractRequirement
    } = this.activatedRoute.snapshot.data.submittalData;

    this.currentUser = currentUserId;
    this.contractId = contractId;
    this.contractCode = this.localContractService.currentContract.Code;
    this.allDocumentType = submittalDocumentType;
    this.showPhaseAndContractRequirement = this.contractCode.includes(FAC_Contracts) || this.contractCode.includes(TSB_Contracts);
    if (submittal) {
      this.loadData(
        submittalType,
        submittalPage,
        submittalDocumentType,
        submittalSpecification,
        phase,
        contractRequirement,
        submittal,
        documents,
      );
    } else {
      this.loadData(
        submittalType,
        submittalPage,
        submittalDocumentType,
        submittalSpecification,
        phase,
        contractRequirement,
      );
    }
  }

  loadData(
    type: SubmittalType[],
    page: SubmittalPage[],
    docType: SubmittalDocumentType[],
    specification: IPRFISpecification[],
    phase: Phase[],
    contractRequirement: IPRFIContractRequirement[],
    submittal?: IPSubItem,
    documents?: IPSubDocumentDownload[],
  ): void {
    function mappingToList(
      item: SubmittalType | SubmittalDocumentType | SubmittalPage | Phase,
    ): ISelectItem {
      return {
        name: item.Title,
        id: item.Guid,
        checked: false,
      };
    }
    function sortByName (a: ISelectItem, b: ISelectItem): number {
      if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    }
    this.submittalTypeList = type.map((item: SubmittalType) => {
      return mappingToList(item);
    });
    this.drawingPageList = page.map((item: SubmittalPage) => {
      return mappingToList(item);
    });
    this.phaseList = phase.map((item: Phase) => {
      return mappingToList(item);
    });
    this.phaseList.sort(sortByName);
    this.contractRequirementList = contractRequirement.map((item: IPRFIContractRequirement) => {
      return { id: item.Guid, name: item.Name, checked: false };
    });
    this.contractRequirementList.sort(sortByName);
    this.masterSpecList = specification.filter((item)=>{return item.SpecType === MasterSpecType}).map((item)=>{
      return { name: `${item.SpecId} ${item.Title}`, id: item.Guid, checked: false }
    })
    this.pageList = page;
    this.specificationList = specification.filter((item)=>{return item.SpecType === UniformatSpecType});
    this.specificationCleanList = this.specificationList.map(
      (item: IPRFISpecification) => {
        return {
          name: `${item.SpecId} - ${item.Title}`,
          id: item.Guid,
          checked: false,
        };
      },
    );
    this.divisionList = [];
    const groups = groupData(_.sortBy(this.specificationList, "SpecId"), "Division");
    groups.forEach((v, k) => {
      if (k.toLowerCase().includes("division")) {
        this.divisionList.push({
          name: k,
          id: k.match(/division \d+/i)[0].replace(/division /i, ""),
          checked: false,
        });
      } else if (k.toLowerCase().includes("agreement db")) {
        this.divisionList.push({
          name: k,
          id: k.match(/agreement db/i)[0].replace(/agreement db /i, ""),
          checked: false,
        });
      } else if (k.toLowerCase().includes("exhibit")) {
        this.divisionList.push({
          name: k,
          id: k.match(/exhibit \w+/i)[0].replace(/exhibit /i, ""),
          checked: false,
        });
      } else {
        this.divisionList.push({
          name: k,
          id: k,
          checked: false,
        });
      }
    });
    console.log(submittal);
    if (submittal) {
      this.submittal = new SubmittalCreate(this.contractId, submittal);
      const selectedSpecification = this.specificationList.find((i) => {
        return i.Guid === this.submittal.submittal_create.SpecificationId;
      });
      
      if (selectedSpecification) {
        this.division =
          this.divisionList.find(
            (i) => i.name === selectedSpecification.Division,
          )?.id || "";
      }
      const hasDisposition = [
        SubmittalStatus.Revise_Resubmit,
        SubmittalStatus.Accepted_With_Comments,
        SubmittalStatus.Reviewed_With_Comments,
        SubmittalStatus.Accepted,
        SubmittalStatus.Reviewed,
      ].some((item) => submittal.Status === item);
      const role = this.localContractUserService.currentUserContractRole;
      this.showVoid = (getVersionString(submittal.SubmittalNumber) === "REV00") 
        && !hasDisposition && (role === SubmittalRole.DBAdmin || (environment.stage === "local" && role === SubmittalRole.Doc_Control));
      if (canRevise(submittal.Status)) {
        this.IsRevision = true;
        // this.submittal = this.submittal.resetSubmittal();
      } else if (
        (submittal.Status === SubmittalStatus.QAQC_Fail || isReviseDraft(submittal)) &&
        submittal.submittal_reject_detail &&
        submittal.submittal_reject_detail.length > 0
      ) {
        const lastReject = submittal.submittal_reject_detail.sort(
          (a, b) =>
            new Date(b.DateUpdated).getTime() -
            new Date(a.DateUpdated).getTime(),
        )[0];
        this.IsRevise = true;
        this.note = lastReject.Note;
        this.rejectedBy = this.localContractUserService.getItems()
          .find((u) => {
            return u.UserId === lastReject.UpdatedBy
          })?.UserInfo?.displayName ?? '';
        const documnetsForReject = documents.filter((item) => {
          return item.ReferenceId === lastReject.Guid;
        });

        if (documnetsForReject.length > 0) {
          this.reviseCoverSheet = getFilesByType(
            documnetsForReject,
            SubmittalFileType.CoverSheetStampSigned,
          );
        } else {
          const docsByStatusAndVersion = documents.filter((item) => {
            return (
              (item.Status === SubmittalStatus.Submitted ||
                item.Status === SubmittalStatus.In_Review) &&
              item.Submittal_version ===
                getVersionString(submittal.SubmittalNumber)
            );
          });

          this.reviseCoverSheet = getFilesByType(
            docsByStatusAndVersion,
            SubmittalFileType.CoverSheetStampSigned,
          );
        }
      }
    } else {
      this.submittal = new SubmittalCreate(this.contractId);
    }
    if (!this.IsRevision && documents && documents.length > 0) {
      const documnetsForThis = documents.filter((item) => {
        return item.Status === SubmittalStatus.Draft;
      });
      console.log("all documents", documnetsForThis);

      this.oldCoverSheet = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CoverSheet,
      );
      this.coverSheet = _.cloneDeep(this.oldCoverSheet);
      this.oldSubmittalDocuments = getFilesByType(
        documnetsForThis,
        SubmittalFileType.SubmittalDocuments,
      );
      this.submittalDocuments = JSON.parse(
        JSON.stringify(this.oldSubmittalDocuments),
      );
    }
    this.documentTypeList = this.generateDocumentTypeList(this.submittal.submittal_create.SubmittalTypeId);
    if (this.submittal.Guid) {
      this.title = this.IsRevision ? "Create New (Revision)" : (this.IsRevise && this.submittal.Status !== SubmittalStatus.Draft) ? "Revise Submittal" : "Edit Draft Submittal";
    } else {
      this.title = "Create New Submittal";
    }

    console.log(this.submittal);
    this.requireUniFormatCategory = this.showPhaseAndContractRequirement === false || this.submittal.submittal_create.ContractRequirementId === SPR_PerformanceSpecifications;
    this.oldSubmittal = _.cloneDeep(this.submittal);
    this.loadingService.stop();
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldSubmittal);
    const current = JSON.stringify(this.submittal);
  
    return (
      old !== current || hasChangesFileModel(this.coverSheet, this.oldCoverSheet) || hasChangesFileModel(this.submittalDocuments, this.oldSubmittalDocuments)
    );
  }

  IsInputValid(): boolean {
    const inValid = Object.values(this.submittal.submittal_create).find(
      (v) => {
        return (
          isString(v) && v !== null && v !== undefined && this.baseFunctionService.isInputInvalid(v)
        );
      },
    );
    return this.getTitleLength() <= this.titleMaxLength &&
    this.getReferenceNumberLength() <= this.referenceNumberMaxLength &&
    this.getPriorityNoteLength() <= this.priorityNoteMaxLength &&
    this.getReferenceNoteLength() <= this.referenceNoteMaxLength &&
    inValid === undefined;
  }

  IsValid(): boolean {
    if (
      !this.submittal.submittal_create.Title ||
      this.submittal.submittal_create.Title.trim() === "" ||
      (this.showPhaseAndContractRequirement && (
        !this.submittal.submittal_create.PhaseId ||
        this.submittal.submittal_create.PhaseId.trim() === "" ||
        !this.submittal.submittal_create.ContractRequirementId ||
        this.submittal.submittal_create.ContractRequirementId.trim() === "")) ||
      (this.requireUniFormatCategory && (
        !this.submittal.submittal_create.SpecificationId ||
        this.submittal.submittal_create.SpecificationId.trim() === "" )) ||
      !this.submittal.submittal_create.DocumentTypeId ||
      this.submittal.submittal_create.DocumentTypeId.trim() === "" ||
      !this.submittal.submittal_create.SubmittalTypeId ||
      this.submittal.submittal_create.SubmittalTypeId.trim() === "" ||
      !this.submittal.submittal_create.DrawingPageId ||
      this.submittal.submittal_create.DrawingPageId.trim() === "" ||
      !this.submittal.submittal_create.Priority ||
      this.submittal.submittal_create.Priority > 3 ||
      this.submittal.submittal_create.Priority < 1 ||
      (this.submittal.submittal_create.Priority === 3 &&
        (!this.submittal.submittal_create.PriorityReason ||
          this.submittal.submittal_create.PriorityReason.trim() === "")) ||
      this.coverSheet.length === 0 ||
      this.submittalDocuments.length === 0 ||
      !this.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    const validation = this.IsInputValid() && this.IsValid();

    if (!validation) {
      return;
    }

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Submittal?";
      modalInstance.instance.body =
        "Your Submittal will be sent to the Doc Control. Once it’s submitted, you will no longer be able to edit the submittal.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_create.IsDraft = false;
          if (this.submittal.submittal_create.DrawingPageId) {
            const baseDate = new Date();
            const drawingPage = this.pageList.find((item) => {
              return item.Guid === this.submittal.submittal_create.DrawingPageId;
            });
            const reviewDate = calculateReviewDate(baseDate, drawingPage);
            this.submittal.submittal_duedate.DueDate = reviewDate.toISOString();
          }

          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()||!this.isFileNameValid()||!this.IsInputValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_create.IsDraft = true;
          delete this.submittal.submittal_duedate;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    this.updateFiles();
    this.baseFunctionService.update(this.submittal);
  }

  updateFiles(): void {
    const allCurrentFiles = [...this.coverSheet, ...this.submittalDocuments];
    const allOldFiles = [
      ...this.oldCoverSheet.map((f) => f.Guid),
      ...this.oldSubmittalDocuments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  setPriority(e: number): void {
    this.submittal.submittal_create.Priority = e;
  }

  setTitle(e: string): void {
    this.submittal.submittal_create.Title = e;
  }

  setBIM360(e: string): void {
    this.submittal.submittal_create.BIM360 = e;
  }

  setReference(e: string): void {
    this.submittal.submittal_create.ReferenceNumber = e;
  }

  setNote(e: string): void {
    this.submittal.submittal_create.ReferenceNote = e;
  }
  setIsConfidential(e: boolean): void {
    this.submittal.submittal_create.IsConfidentail = e;
  }

  setDivisionList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.division = e.id;
      this.submittal.submittal_create.SpecificationId = undefined;
      this.specificationCleanList = [];

      this.specificationList
        .filter((i) => {
          return (
            (i.Division.toLowerCase().includes("division") &&
              i.Division?.match(/division \d+/i)[0].replace(
                /division /i,
                "",
              ) === e?.id) ||
            (i.Division.toLowerCase().includes("agreement db") &&
              i.Division?.match(/agreement db/i)[0].replace(
                /agreement db /i,
                "",
              ) === e?.id) ||
            (i.Division.toLowerCase().includes("exhibit") &&
              i.Division?.match(/exhibit \w+/i)[0].replace(/exhibit /i, "") ===
                e?.id) ||
            (i.Division &&
              i.Division === e?.id)
          );
        })
        .forEach((item) => {
          this.specificationCleanList.push({
            name: `${item.SpecId} - ${item.Title}`,
            id: item.Guid.toString(),
            checked: false,
          });
        });
    }
  }

  setSpecificationList(e: ISelectItem | any): void {
    if (e?.target?.value === "" || e === "") {
      this.submittal.submittal_create.SpecificationId = null;
    } else if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.SpecificationId = e?.id;
    }
  }

  setMasterSpecificationList(e: ISelectItem | any): void {
    if (e?.target?.value === "" || e === "") {
      this.submittal.submittal_create.MasterSpecId = null;
    } else if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.MasterSpecId = e?.id;
    }
  }

  setSubmittalTypeList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.SubmittalTypeId = e?.id;
      this.submittal.submittal_create.DocumentTypeId = undefined;
      this.documentTypeList = this.generateDocumentTypeList(this.submittal.submittal_create.SubmittalTypeId);
    }
  }

  setDocumentTypeList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.DocumentTypeId = e?.id;
    }
  }

  generateDocumentTypeList(reviewType: string | undefined | null): ISelectItem[] {
    function mappingToList(item: SubmittalDocumentType): ISelectItem {
      return {
        name: item.Title,
        id: item.Guid,
        checked: false,
      };
    }
    function sortByName (a: ISelectItem, b: ISelectItem): number {
      if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    }
    let docType: SubmittalDocumentType[] = [];
    if(!reviewType) {
      docType= this.allDocumentType;
    } else if(reviewType === ReviewTypeReadOnly)
    {
      docType= this.allDocumentType.filter((dt)=>{return dt.ReviewType&&dt.ReviewType.includes(reviewType)});
    } else {
      docType= this.allDocumentType.filter((dt)=>{return dt.ContractCode || (dt.ReviewType&&dt.ReviewType.includes(reviewType))});
    }
    const documentTypeList = docType.map((item: SubmittalDocumentType) => {
      return mappingToList(item);
    });
    documentTypeList.sort(sortByName);
    return documentTypeList;
  }
  
  setDrawingPageList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.DrawingPageId = e?.id;
    }
  }

  setPhaseList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.PhaseId = e?.id;
    }
  }

  setContractRequirementList(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_create.ContractRequirementId = e?.id;
      this.requireUniFormatCategory = this.showPhaseAndContractRequirement === false || this.submittal.submittal_create.ContractRequirementId === SPR_PerformanceSpecifications;
    }
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            versionConst + "00",
            SubmittalStatus.Draft,
            this.submittal.Guid &&
            this.submittal.Guid !== "" &&
            !canRevise(this.submittal.Status)
              ? this.submittal.Guid
              : this.submittal.tempId,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    this.submittalDocumentService
      .removeSubmittalDocument(e.Guid)
      .subscribe();
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getTitleLength(): number {
    return this.submittal.submittal_create?.Title
      ? this.submittal.submittal_create?.Title.length
      : 0;
  }

  getReferenceNumberLength(): number {
    return this.submittal.submittal_create?.ReferenceNumber
      ? this.submittal.submittal_create?.ReferenceNumber.length
      : 0;
  }

  getReferenceNoteLength(): number {
    return this.submittal.submittal_create?.ReferenceNote
      ? this.submittal.submittal_create?.ReferenceNote.length
      : 0;
  }

  getPriorityNoteLength(): number {
    return this.submittal.submittal_create?.PriorityReason
      ? this.submittal.submittal_create?.PriorityReason.length
      : 0;
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case SubmittalFileType.CoverSheet:
        index = this.coverSheet.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.coverSheet.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.coverSheet.push(item);
            this.coverSheet = this.coverSheet.slice();
          }
        }

        break;
      case SubmittalFileType.SubmittalDocuments:
        index = this.submittalDocuments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.submittalDocuments.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.submittalDocuments.push(item);
            this.submittalDocuments = this.submittalDocuments.slice();
          }
        }
        break;
      default:
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.coverSheetNameValid = true;
    this.coverSheet.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.coverSheetNameValid = false;
      }
    });
    this.submittalDocumentsNameValid = true;
    this.submittalDocuments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.submittalDocumentsNameValid = false;
      }
    });
    return this.coverSheetNameValid && this.submittalDocumentsNameValid;
  }

  hasFilesUploading(): boolean {
    return (
      this.coverSheet.some((item) => item.Status === UploadStatus.UPLOADING) ||
      this.submittalDocuments.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      )
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }

  voidSubmittal(): void {
    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      this.baseFunctionService.dbVoidSubmittal();
    }
  }
}
